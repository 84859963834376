import React, { useEffect, useState } from "react";

export default function Footer(props) {
  

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_right">
            {/* <ul className="footer_menu_links">              
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms of Use</a>
              </li>
            </ul> */}
          </div>
          <div className="footer_panel_left">
            <p>© {new Date().getFullYear()} <a href="/" target="_blank">DOPAMINE INTERNATIONAL.</a></p>
          
          </div>
        </div>
      </div>
    </footer>
  );
}
